<script>
  import WeekStatsInfo from '@/components/profile/week-stats-info/WeekStatsInfo.vue';
  import Chart from 'chart.js';
  import formatMinutes from '@/utils/formatMinutes.js';
  import { hexToRgb } from '@/utils';

  export default {
    components: {
      WeekStatsInfo,
    },
    mounted() {
      this.mountChart();
    },
    props: {
      streak: {
        type: Number,
        required: true,
      },
      consumedTimeByDay: {
        type: Array,
        required: true,
      },
      exercicesNumber: {
        type: Number,
        required: true,
      },
    },
    computed: {
      // consumedTimeByDayInHours() {
      //   return this.consumedTimeByDay.map((minutes) => minutes / 60);
      // },
      totalTimeFormatted() {
        // sum the minutes
        let totalMinutes = this.consumedTimeByDay.reduce((sum, current) => sum + current, 0);
        const { totalHours, remainingMinutes } = formatMinutes(totalMinutes);
        return `${totalHours}HR ${remainingMinutes}MIN`;
      },
    },
    methods: {
      mountChart() {
        const ctx = this.$refs.chartCanvas.getContext('2d');
        const gradient = ctx.createLinearGradient(0, 25, 0, 300);

        const color = this.$vuetify.theme.themes.light.primaryColor;

        gradient.addColorStop(0, `rgba(${hexToRgb(color)}, 0.36)`);
        gradient.addColorStop(0.35, 'rgba(61, 222, 214, 0)');
        new Chart(ctx, {
          type: 'bar',
          data: {
            labels: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
            datasets: [
              {
                type: 'line',
                data: this.consumedTimeByDay,
                backgroundColor: gradient,
                borderColor: color,
                borderWidth: 5,
              },
            ],
          },
          options: {
            legend: {
              display: false,
            },
            elements: {
              point: {
                radius: 0,
              },
            },
            scales: {
              yAxes: [
                {
                  gridLines: {
                    display: false,
                  },
                  ticks: {
                    beginAtZero: true,
                    suggestedMax: 30,
                    stepSize: 15,
                  },
                },
              ],
              xAxes: [
                {
                  gridLines: {
                    display: false,
                  },
                },
              ],
            },
          },
        });
      },
      setChartOption(option) {
        this.chartOption = option;
      },
    },
  };
</script>
